<template>
	<div>
		
		<div class="Top">
			
			<div class="OrderTypes">
				<span>全部订单</span>
				<span>待收货</span>
				<span>已完成</span>
				<span>退换/售后</span>
				<span  class="On">常购商品</span>
			</div>
			
			<div class="OrderSearch">
				
				<li>
					<el-select placeholder="请选择">
					    <el-option label="按采购单编号查询"></el-option>
						 <el-option label="按采购单名称查询"></el-option>
						  <el-option label="按平台订单号查询"></el-option>
						  <el-option label="按京东订单号查询"></el-option>
						  <el-option label="按苏宁订单号查询"></el-option>
						  <el-option label="按天猫订单号查询"></el-option>
						  <el-option label="按商品关键词查询"></el-option>
						  <el-option label="按订单状态查询"></el-option>
					  </el-select>
				</li>
				
				<li>
					<el-input></el-input>
				</li>
				
				<li>
					<el-button icon="el-icon-search">搜索订单</el-button>
				</li>
				
			</div>
			
		</div>
		
		<div class="GoodsList">
			
			<div class="Goods" v-for="item in 12" :key="item">
				<ul class="Info">
					<img src="https://imgservice.suning.cn/uimg1/b2c/image/0UAR5mAjmI1FrGfYnHrNgQ.jpg?format=400h_400w_4e_80q" />
					<li>
						<span class="Title">
							三只松鼠休闲零食网红孕妇儿童早餐面包蛋糕…
						</span>
						<span class="Price">￥36.2</span>
					</li>
				</ul>
				<ul class="Do">
					<span class="Look">
						查看商品
					</span>
					<span class="Cart">
						加入购物车
					</span>
				</ul>
			</div>
			
		</div>
		
		
		<div class="Page">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :total="1000">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Pagination} from 'element-ui'
	export default {
	  name: 'MyOrderList',
	  props: {
	  },
	  data() {
	      return {
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-pagination':Pagination
	  },
	  methods:{
	  }
	}
</script>

<style scoped>
	.Top{
		display: flex;
		margin: 20px 20px 0px 20px;
		background-color: #FFFFFF;
		align-items: center;
		padding: 25px;
		
	}
	.Top .OrderTypes{
		flex: 1;
	}
	.OrderTypes span{
		margin-right: 15px;
		cursor: pointer;
	}
	.OrderTypes span.On{
		text-decoration: underline;
	}
	.Top .OrderSearch{
		display: flex;
	}
	.Top .OrderSearch li{
		margin-left: 10px;
	}
	
	.GoodsList{
		display: flex;
		margin: 20px;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	
	.Goods{
		width: 24%;
		height: 140px;
		display: flex;
		background-color: #FFFFFF;
		margin-bottom: 20px;
		flex-wrap: wrap;
		padding-top: 10px;
		flex-direction: column;
	}
	.Goods img{
		width: 60px;
		height: 60px;
	}
	.Goods ul.Info{
		padding: 10px;
		display: flex;
		flex: 1;
	}
	.Goods ul.Info img{
		width: 80px;
		height: 80px;
		margin-right: 10px;
		
	}
	.Goods ul.Info li{
		flex: 1;
	}
	.Goods ul.Info li span{
		display: block;
		
	}
	.Goods ul.Info span.Price{
		font-size: 1.2rem;
		color: rgba(228,0,0,1);
		margin-top: 10px;
	}
	
	.Goods ul.Do{
		width: 100%;
		height: 30px;
		display: flex;
		text-align: center;
		line-height: 30px;
		color: #FFFFFF;
	}
	.Goods ul.Do .Look{
		width: 50%;
		height: 30px;
		background-color: rgba(228,0,0,0.4);
		cursor: pointer;
	}
	.Goods ul.Do .Look:hover{
		background-color: rgba(228,0,0,0.55);
	}
	.Goods ul.Do .Cart{
		width: 50%;
		height: 30px;
		background-color: rgba(228,0,0,0.8);
		cursor: pointer;
	}
	.Goods ul.Do .Cart:hover{
		background-color: rgba(228,0,0,0.95);
	}
</style>
